import moment from 'moment';
import { handlePasteTableFromExcel } from 'services/utils/import-utils';
import { uuidUtil } from 'services/utils/uuid-util.js';

export const importContactInformation = (e) => {
  const res = [];
  const table = handlePasteTableFromExcel(e);

  if (table.length > 0) {
    const columns = [];

    table[0].forEach((_, col) => {
      switch (columns.length) {
        case 0:
          columns.push(['description', col]); // col = 0
          break;
        case 1:
          if (checkNumberColumn(table, col)) columns.push(['amount', col]);
          break;
        case 2:
          if (checkDateColumn(table, col)) columns.push(['start', col]);
          break;
        case 3:
          if (checkDateColumn(table, col)) columns.push(['completion', col]);
          break;
        case 4:
          if (checkBoolColumn(table, col))
            columns.push(['include_amount', col]);
          break;
        case 5:
          if (checkBoolColumn(table, col)) columns.push(['include_team', col]);
          break;
        default:
          break;
      }
    });

    if (columns.length >= 4) {
      table.forEach((row) => {
        const rowData = {
          isEdit: true,
          id: 'newItem' + uuidUtil.uuidv4(),
          description: '',
          amount: '',
          start: '',
          completion: '',
          duration: '',
          include_amount: false,
          include_team: false,
        };

        columns.forEach(([key, col]) => {
          if (key === 'completion' || key === 'start')
            rowData[key] =
              row[col] && moment(row[col]).isValid()
                ? moment(row[col]).format('YYYY-MM-DD')
                : '';
          else if (key === 'include_amount' || key === 'include_team')
            rowData[key] = row[col] === 'true';
          else rowData[key] = row[col] || '';
        });
        res.push(rowData);
      });
    }
  }

  return res;
};

export const importBudgetInformationOne = (e, item, existingRows) => {
  const res = [];
  let table = handlePasteTableFromExcel(e);

  //  Remove Empty Rows
  table = table.filter((row) => row.some((cell) => cell && cell.trim() !== ''));

  //  Remove Title Row
  if (
    table.length > 0 &&
    table[0].filter((cell) => cell.trim() !== '').length === 1
  ) {
    console.log('Skipping table title:', table[0]);
    table.shift();
  }

  //  Remove Header Row
  if (
    table.length > 0 &&
    table[0].length >= 3 &&
    !checkNumberColumn(table, 1) &&
    !checkNumberColumn(table, 2)
  ) {
    table.shift();
  }

  if (table.length > 0) {
    const columns = [];

    table[0].forEach((_, col) => {
      switch (columns.length) {
        case 0:
          columns.push(['description', col]); // col = 0
          break;
        case 1:
          if (checkNumberColumn(table, col))
            columns.push(['original_cost', col]);
          break;
        case 2:
          if (checkNumberColumn(table, col))
            columns.push(['previous_cost', col]);
          break;
        case 3:
          if (checkNumberColumn(table, col))
            columns.push(['current_cost', col]);
          break;
        default:
          break;
      }
    });

    if (columns.length >= 2) {
      table.forEach((row) => {
        const rowDescription = row[columns[0][1]] || '';
        const rowOriginalCost = row[columns[1][1]] || '';
        const rowPreviousCost =
          (columns.length >= 3 ? row[columns[2][1]] : row[columns[1][1]]) || '';
        const rowCurrentCost =
          (columns.length >= 4 ? row[columns[3][1]] : row[columns[1][1]]) || '';

        const rowData = {
          id: 'newItem' + uuidUtil.uuidv4(),
          isEdit: true,
          description: rowDescription,
          original_cost: toNumber(rowOriginalCost),
          previous_cost: toNumber(rowPreviousCost),
          current_cost: toNumber(rowCurrentCost),
          cost_sf: '',
          project_report_budget_category_id:
            item.project_report_budget_category_id,
          parent_id: item.parent_id,
        };
        res.push(rowData);
      });
    }
  }

  return res;
};

export const importBudgetInformationTwo = (e, item) => {
  let res = [];
  let table = handlePasteTableFromExcel(e);

  //  Remove Empty Rows
  table = table.filter((row) => row.some((cell) => cell && cell.trim() !== ''));

  //  Remove Title Row
  if (
    table.length > 0 &&
    table[0].filter((cell) => cell.trim() !== '').length === 1
  ) {
    console.log('Skipping table title:', table[0]);
    table.shift();
  }

  //  Remove Header Row
  if (
    table.length > 0 &&
    table[0].length >= 3 &&
    !checkNumberColumn(table, 1) &&
    !checkNumberColumn(table, 2)
  ) {
    table.shift();
  }

  if (table.length > 0) {
    const columns = [];

    table[0].forEach((_, col) => {
      switch (columns.length) {
        case 0:
          columns.push(['description', col]);
          break;
        case 1:
          if (checkNumberColumn(table, col))
            columns.push(['contract_amount', col]);
          break;
        case 2:
          if (checkNumberColumn(table, col))
            columns.push(['paid_to_date_amount', col]);
          break;
        default:
          break;
      }
    });

    if (columns.length >= 2) {
      table.forEach((row) => {
        const rowDescription = row[columns[0][1]] || '';
        const rowContractAmount = row[columns[1][1]] || '';
        const rowPaidToDateAmount =
          (columns.length >= 3 ? row[columns[2][1]] : '') || '';

        res.push({
          id: 'newItem' + uuidUtil.uuidv4(),
          isEdit: true,
          description: rowDescription,
          contract_amount: toNumber(rowContractAmount),
          paid_to_date_amount: toNumber(rowPaidToDateAmount),
          project_report_budget_category_id:
            item.project_report_budget_category_id,
          parent_id: item.parent_id,
          fromExcel: true,
        });
      });
    }
  }

  return res;
};

export const importStatusInformation = (e, item) => {
  const res = [];
  const table = handlePasteTableFromExcel(e);

  if (table.length > 0) {
    if (table[0].length >= 2) {
      const number = parseInt(item.code.split('.')[0], 10);
      const first = parseInt(item.code.split('.')[1], 10) - 1;

      table.forEach((row, i) => {
        const statusNumber = first + i;
        const rowData = {
          isEdit: true,
          id: 'newItem' + uuidUtil.uuidv4(),
          project_report_status_type_id: item.project_report_status_type_id,
          date: moment().format('YYYY-MM-DD'),
          description: row[1] || '',
          code: `${number}.${statusNumber < 9 ? 0 : ''}${statusNumber + 1}`,
        };

        res.push(rowData);
      });
    }
  }

  return res;
};

const checkDateColumn = (table, colIndex) => {
  return table.slice(1).every((r) => {
    const content = r[colIndex];
    return content === '' || moment(content).isValid();
  });
};

/* const checkNumberColumn = (table, colIndex) => {
  return table.slice(1).every((r) => {
    const content = r[colIndex];
    return content === '' || !isNaN(content);
  });
}; */

const checkNumberColumn = (table, colIndex) => {
  return table.slice(1).every((r) => {
    const content = r[colIndex];

    if (typeof content !== 'string') return !isNaN(content); // If it's already a number, return true

    // Remove thousands separators (comma or dot) but keep the decimal separator
    let normalized = content.replace(/[^0-9.,]/g, ''); // Remove all non-numeric characters except . and ,

    // Handle different number formats
    if (/^\d{1,3}(\.\d{3})*,\d{2}$/.test(normalized)) {
      // European format (1.234.567,89) -> Replace dots with nothing, change comma to dot
      normalized = normalized.replace(/\./g, '').replace(',', '.');
    } else if (/^\d{1,3}(,\d{3})*\.\d{2}$/.test(normalized)) {
      // US format (1,234,567.89) -> Remove commas
      normalized = normalized.replace(/,/g, '');
    } else {
      // If it's a whole number with commas (e.g., "1,234,567") or dots (e.g., "1.234.567")
      normalized = normalized.replace(/[,.]/g, '');
    }

    return !isNaN(parseFloat(normalized));
  });
};

const checkBoolColumn = (table, colIndex) => {
  return table.slice(1).every((r) => {
    const content = r[colIndex];
    return (
      content === '' ||
      content.toLowerCase() === 'true' ||
      content.toLowerCase() === 'false'
    );
  });
};

const toNumber = (str) => {
  if (!str) return 0;
  // Remove all commas from the string
  const cleaned = str.replace(/,/g, '');
  return parseFloat(cleaned);
};
