/* eslint-disable no-useless-escape */
import React, { Component, Fragment } from 'react';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TextareaAutosizeWrapper from 'components/shared/input/TextareaAutosizeWrapper';
import CurrencyInput from 'components/CurrencyInput';
import CheckBox from 'components/shared/checkbox/CheckBox';
import moment from 'moment';
import numeral from 'numeral';
import Date from 'components/shared/text/Date';
import Menu, { MENU_SELECTOR } from 'components/shared/menu/Menu';
import dragDropIcon from 'images/dragDropIcon.svg';
import dragDropIconDark from 'images/dragDropIconDark.svg';
import arrowIcon from 'images/arrow-green.svg';
import arrowIconDark from 'images/arrow-neon.svg';
import {
  importBudgetInformationOne,
  importBudgetInformationTwo,
} from 'services/utils/project-report-util';

import './ReportsForm.css';

const headersOne = [
  'DESCRIPTION *',
  'ORIGINAL*',
  'PREVIOUS',
  'CURRENT *',
  'VARIANCE ORIGINAL',
  'VARIANCE PREVIOUS',
  'COST/SF',
  'ACTION',
];
const headersTwo = [
  'DESCRIPTION *',
  'BUDGET',
  'PAID TO DATE',
  'BALANCE',
  'COMPLETE %',
  '',
  'ACTION',
];

class BudgetTable extends Component {
  static propTypes = {
    darkMode: PropTypes.bool.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape).isRequired,
    onOrderChange: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired,
    onResolved: PropTypes.func.isRequired,
    onAddRow: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onAddMultiple: PropTypes.func.isRequired,
    missingField: PropTypes.bool.isRequired,
    status: PropTypes.string,
    canDelete: PropTypes.bool.isRequired,
    reportId: PropTypes.number.isRequired,
  };

  static defaultProps = {
    status: '',
  };

  constructor(props) {
    super(props);
    this.state = { cols: [], primaryData: {}, collapsedCategories: [] };
  }

  componentDidMount() {
    this.cols();
    const origialCostField = {};
    this.props.data.forEach(
      (item) => (origialCostField[item.id] = item.original_cost)
    );
    this.setState({ primaryData: origialCostField });
  }

  cols = () => {
    const { typeId } = this.props;
    const headers = typeId === 1 ? headersOne : headersTwo;
    let newCols = [];
    if (headers.length > 0) {
      for (let i = 0; i < headers.length; i++) {
        newCols.push(<col key={i + 1} />);
      }
    }
    this.setState({ cols: newCols });
  };

  onDragEnd = (result) => {
    const { onOrderChange } = this.props;
    const { destination, source } = result;
    if (!destination || destination.index === source.index) return;
    onOrderChange(result);
  };

  toFormat = (number) => {
    if (!number && number !== 0) {
      return '--';
    }
    const currency = numeral(number).format('$0,0.00');
    const result = currency.endsWith('.00') ? currency.slice(0, -3) : currency;
    return result;
  };

  getTotalAmounts = (array, key) => {
    return array.reduce((sum, item) => (sum += +item[key] || 0), 0);
  };

  viewTextInput = (attr, item, required = true) => {
    const value = item[attr];
    return (
      <CurrencyInput
        key={attr + item.id}
        placeholder="--"
        type="text"
        value={value || value === 0 ? value : ''}
        showMask={!!value}
        onBlur={(e) => {
          const value = e.target.value.replace(/[^0-9\.\-]/g, '');
          this.props.onValueChange(attr, value, item);
        }}
        darkMode={this.props.darkMode}
        maskOptions={{ allowNegative: true }}
        valid={required ? !(this.props.missingField && !value) : true}
      />
    );
  };

  viewPayToDateInput = (item) => {
    const { id, paid_to_date_amount: value } = item;
    const hasChildren = item.children && item.children.length > 0;

    if (hasChildren) return this.toFormat(value);

    return (
      <CurrencyInput
        key={'paid_to_date_amount' + id}
        placeholder="--"
        type="text"
        value={value || value === 0 ? value : ''}
        onChange={(e) => {
          const value = e.target.value.replace(/[^0-9\.\-]/g, '');
          this.props.onValueChange('paid_to_date_amount', value, item);
        }}
        maskOptions={{ allowNegative: true }}
        darkMode={this.props.darkMode}
      />
    );
  };

  renderButtonAction = (key, label, onClick) => (
    <span
      key={key}
      className={classnames({
        'select-item': true,
        'select-item-active': true,
      })}
      onClick={onClick}
    >
      {label}
    </span>
  );

  viewActions = (item) => {
    const { onResolved, onDelete, onAddRow, canDelete } = this.props;
    const isCurrent = !item.previous_published_report_id || item.isEdit;
    const canResolve =
      !isCurrent &&
      !!item.description &&
      (item.project_report_budget_category_id === 1
        ? item.original_cost > 0 && item.current_cost > 0
        : item.contract_amount > 0 && item.paid_to_date_amount > 0);

    const actions = [];

    if (!item.parent_id)
      actions.push({ content: 'Add Sub Item', onClick: () => onAddRow(item) });

    if (canResolve) {
      actions.push({
        content: 'Resolved',
        onClick: () =>
          onResolved({
            ...item,
            resolved_at: moment(new Date()).format('YYYY-MM-DD'),
          }),
      });
    }

    if (canDelete || isCurrent) {
      actions.push({
        content: 'Delete',
        onClick: () => onDelete(item, !item.isEdit),
      });
    }

    return (
      <div className="table-action">
        {actions.length > 0 && (
          <Menu
            key="more-actions"
            selector={MENU_SELECTOR.MORE_OPTIONS_VERTICAL}
            items={actions}
          />
        )}
      </div>
    );
  };

  calculateVariance = (original, current) => {
    if (!original || !current) return '--';

    return parseFloat(current || '0') - parseFloat(original || '0');
  };

  calculateOriginalVariance = (item) => {
    return this.calculateVariance(item.original_cost, item.current_cost);
  };

  calculatePreviousVariance = (item) => {
    return this.calculateVariance(item.previous_cost, item.current_cost);
  };

  toggleCategory = (itemId) => {
    let collapsedCategories = [];
    if (this.state.collapsedCategories.includes(itemId)) {
      collapsedCategories = this.state.collapsedCategories.filter(
        (i) => i !== itemId
      );
    } else {
      collapsedCategories = [...this.state.collapsedCategories, itemId];
    }
    this.setState({ collapsedCategories });
  };

  renderRows = (item) => {
    if (this.props.typeId === 1) return this.renderRowsTypeOne(item);
    else return this.renderRowsTypeTwo(item);
  };

  renderRowIcon = (hasChildren = false) => {
    const { darkMode } = this.props;
    return hasChildren
      ? darkMode
        ? arrowIconDark
        : arrowIcon
      : darkMode
      ? dragDropIconDark
      : dragDropIcon;
  };

  onImportFromExcel = (item) => (e) => {
    const data =
      this.props.typeId === 1
        ? importBudgetInformationOne(e, item)
        : importBudgetInformationTwo(e, item);

    if (data.length > 0) {
      data[0].id = item.id;
      this.props.onAddMultiple(item, data);
      e.target.blur();
    }
  };

  renderRowsTypeOne = (item) => {
    const { onValueChange, missingField } = this.props;
    const hasChildren = item.children && item.children.length > 0;
    const isChild = !!item.parent_id;

    const itemClassname = `${hasChildren ? 'parent-item-cell' : ''} ${
      isChild ? 'child-item-cell' : ''
    }`;

    const icon = this.renderRowIcon(hasChildren);

    return (
      <Fragment key={item.id}>
        <td key={1} className={`td-icon ${itemClassname}`}>
          <div
            className="drag-drop-icon"
            onClick={
              hasChildren ? () => this.toggleCategory(item.id) : undefined
            }
          >
            <img src={icon} alt="drag and drop" />
          </div>
          <div className="separate-icon">
            <div className="description">
              <TextareaAutosizeWrapper
                key={item.id}
                placeholder="Description"
                value={item.description || ''}
                onChange={(value) => onValueChange('description', value, item)}
                valid={!(missingField && !item.description)}
                onPaste={this.onImportFromExcel(item)}
              />
            </div>
          </div>
        </td>
        <td key={2} className={itemClassname}>
          {(item.isEdit || +this.state.primaryData[item.id] === 0) &&
          !hasChildren
            ? this.viewTextInput('original_cost', item, true)
            : this.toFormat(item.original_cost)}
        </td>
        <td key={3} className={itemClassname}>
          {!hasChildren
            ? this.viewTextInput('previous_cost', item, false)
            : this.toFormat(item.previous_cost)}
        </td>
        <td key={4} className={itemClassname}>
          {!hasChildren
            ? this.viewTextInput('current_cost', item)
            : this.toFormat(item.current_cost)}
        </td>
        <td key={5} className={itemClassname}>
          {item.original_variance !== '--'
            ? this.toFormat(item.original_variance)
            : '--'}
        </td>
        <td key={6} className={itemClassname}>
          {item.previous_variance !== '--'
            ? this.toFormat(item.previous_variance)
            : '--'}
        </td>
        <td key={7} className={itemClassname}>
          {item.cost_sf !== '--' ? this.toFormat(item.cost_sf) : '--'}
        </td>

        <td key={8} className={itemClassname}>
          {!hasChildren && (
            <span>
              <CheckBox
                checked={item.construction}
                title={'Add to construction budget'}
                className="construction-budget"
                onChange={() =>
                  onValueChange('construction', !item.construction, item)
                }
              />
            </span>
          )}
        </td>
        <td key={9} className={itemClassname}>
          {this.viewActions(item)}
        </td>
      </Fragment>
    );
  };

  renderRowsTypeTwo = (item) => {
    const { onValueChange, missingField } = this.props;
    const hasChildren = item.children && item.children.length > 0;
    const isChild = !!item.parent_id;

    const itemClassname = `${hasChildren ? 'parent-item-cell' : ''} ${
      isChild ? 'child-item-cell' : ''
    }`;

    const icon = this.renderRowIcon(hasChildren);

    const payToDateAmount =
      item.contract_amount || item.contract_amount === 0
        ? this.viewPayToDateInput(item)
        : '--';

    return (
      <Fragment key={item.id}>
        <td key={1} className={`td-icon ${itemClassname}`}>
          <div
            className="drag-drop-icon"
            onClick={
              hasChildren ? () => this.toggleCategory(item.id) : undefined
            }
          >
            <img src={icon} alt="drag and drop" />
          </div>
          <div className="separate-icon">
            <div className="description">
              <TextareaAutosizeWrapper
                placeholder="Description"
                value={item.description || ''}
                onChange={(value) => onValueChange('description', value, item)}
                valid={!(missingField && !item.description)}
                onPaste={this.onImportFromExcel(item)}
              />
            </div>
          </div>
        </td>

        <td key={2} className={itemClassname}>
          {!hasChildren
            ? this.viewTextInput('contract_amount', item)
            : this.toFormat(item.contract_amount)}
        </td>
        <td key={3} className={itemClassname}>
          {payToDateAmount}
        </td>
        <td key={4} className={itemClassname}>
          {item.contract_amount ? this.toFormat(item.balance) : '--'}
        </td>
        <td key={5} className={itemClassname}>
          {item.complete !== '--'
            ? 100 - Math.round(item.complete) + '%'
            : '--'}
        </td>
        <td key={6} className={itemClassname}>
          {!hasChildren && (
            <span>
              <CheckBox
                checked={item.construction}
                title={'Add to construction budget'}
                className="construction-budget"
                onChange={() =>
                  onValueChange('construction', !item.construction, item)
                }
              />
            </span>
          )}
        </td>
        <td key={7} className={itemClassname}>
          {this.viewActions(item)}
        </td>
      </Fragment>
    );
  };

  getBalance = (item) => {
    if (item.contract_amount && item.paid_to_date_amount) {
      return item.contract_amount - item.paid_to_date_amount;
    } else if (
      item.contract_amount &&
      (item.paid_to_date_amount === '--' || !item.paid_to_date_amount)
    ) {
      return item.contract_amount;
    } else {
      return '--';
    }
  };

  getTotalCompletePercentage = (payToDateTable) => {
    let contractAmount = this.getTotalAmounts(
      payToDateTable,
      'contract_amount'
    );
    let balance = this.getTotalAmounts(payToDateTable, 'balance');
    let remainder = contractAmount - balance;
    return remainder === 0 ? 0 : Math.round((remainder / contractAmount) * 100);
  };

  getItemsTypeOne = (data = []) => {
    const { sqFootage } = this.props;
    return data
      .map((item, index) => {
        if (item.project_report_budget_category_id !== 1) return item;

        const original_variance = this.calculateOriginalVariance(item);
        const previous_variance = this.calculatePreviousVariance(item);
        const cost = item.current_cost ? item.current_cost : item.original_cost;
        return {
          ...item,
          position: index,
          children: this.getItemsTypeOne(item.children),
          original_variance: original_variance === '--' ? 0 : original_variance,
          previous_variance: previous_variance === '--' ? 0 : previous_variance,
          cost_sf: cost ? cost / sqFootage : 0,
        };
      })
      .filter(
        (item) =>
          item.project_report_budget_category_id === 1 &&
          !item._destroy &&
          !item.resolved_at
      );
  };

  getItemsTypeTwo = (data = []) => {
    return data
      .map((item, index) => {
        if (item.project_report_budget_category_id !== 2) return item;

        const balance = this.getBalance(item);
        let complete =
          balance !== '--' && +item.contract_amount !== 0
            ? (balance / item.contract_amount) * 100
            : '--';
        if (+item.contract_amount === 0 && +item.paid_to_date_amount === 0)
          complete = 100;

        return {
          ...item,
          balance,
          complete,
          position: index,
          children: this.getItemsTypeTwo(item.children),
        };
      })
      .filter(
        (item) =>
          item.project_report_budget_category_id === 2 &&
          !item._destroy &&
          !item.resolved_at
      );
  };

  getTotalsTypeOne = (table) => {
    const totals = ['Total Amounts'];
    [
      'original_cost',
      'previous_cost',
      'current_cost',
      'original_variance',
      'previous_variance',
      'cost_sf',
    ].forEach((key) =>
      totals.push(this.toFormat(this.getTotalAmounts(table, key)))
    );
    totals.push('');
    totals.push('');
    return totals;
  };

  getTotalsTypeTwo = (table) => {
    const totals = ['Total Amounts'];
    ['contract_amount', 'paid_to_date_amount', 'balance'].forEach((key) =>
      totals.push(this.toFormat(this.getTotalAmounts(table, key)))
    );
    totals.push(this.getTotalCompletePercentage(table) + '%');
    totals.push('');
    totals.push('');

    return totals;
  };

  getHeaderTypeOne = (table) => [
    'DESCRIPTION *',
    'ORIGINAL *',
    'PREVIOUS',
    'CURRENT *',
    'ORIGINAL VARIANCE',
    'PREVIOUS VARIANCE',
    'COST/SF',
    'CONSTRUCTION',
    this.props.canDelete || table.find((item) => !item.isEdit) ? 'ACTION' : '',
  ];

  getHeaderTypeTwo = (table) => [
    'DESCRIPTION *',
    'BUDGET',
    'PAID TO DATE',
    'BALANCE',
    'COMPLETE %',
    'CONSTRUCTION',
    this.props.canDelete || table.find((item) => !item.isEdit) ? 'ACTION' : '',
  ];

  handleExcelSelectionDrop = (e) => {
    // Attempt to read the plain text
    const textData = e.dataTransfer.getData('text');
    const htmlData = e.dataTransfer.getData('text/html');
    if (textData) {
      // Typically tab-separated rows
      const rows = textData.split('\n').map((row) => row.split('\t'));
      console.log('Dropped text from Excel (tab-delimited):', rows);
    } else if (htmlData) {
      console.log('Dropped html from Excel (tab-delimited):', htmlData);
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlData, 'text/html');
      console.log('Parsed document body:', doc.body.innerHTML);

      // 2) Grab the first <table> element (LibreOffice or Excel selection is typically a table)
      const table = doc.querySelector('table');
      if (!table) {
        console.log('No <table> found in dropped HTML');
        return;
      }

      // 3) For each row, read the <td> cells
      const rows = Array.from(table.querySelectorAll('tr'));
      const rowData = rows.map((row) => {
        const cells = Array.from(row.querySelectorAll('td, th'));
        // Return just the text content of each cell, or innerHTML if you prefer
        return cells.map((cell) => cell.innerText.trim());
      });

      console.log('Parsed data from LibreOffice HTML drop:', rowData);
      // You might parse the HTML if plain text is empty
      // (some Excel versions might only supply HTML, or do both)
      // Then parse that accordingly...
    }
  };

  handleDragOver = (e) => {
    e.preventDefault();
  };

  render() {
    const { collapsedCategories } = this.state;
    const { typeId, data } = this.props;
    const tableData =
      typeId === 1 ? this.getItemsTypeOne(data) : this.getItemsTypeTwo(data);
    const headers =
      typeId === 1
        ? this.getHeaderTypeOne(tableData)
        : this.getHeaderTypeTwo(tableData);
    const totals =
      typeId === 1
        ? this.getTotalsTypeOne(tableData)
        : this.getTotalsTypeTwo(tableData);

    const tableClassnames = classnames({
      'simple-table': true,
      'projects-table': true,
      'field-project-report-budget-table': true,
      'budget-summary': typeId === 1,
      'budget-pay-to-date': typeId === 2,
    });

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="budgets" type="mainItems">
          {(provided) => (
            <div
              {...provided.droppableProps}
              className="table-with-form"
              ref={provided.innerRef}
            >
              <table className="table-draggable-subitems">
                <tbody>
                  <tr>
                    <td>
                      <Table className={tableClassnames}>
                        <thead>
                          <tr>
                            {headers.map((element, index) => {
                              return (
                                <th key={index}>
                                  <span>{element}</span>
                                </th>
                              );
                            })}
                          </tr>
                        </thead>
                      </Table>
                    </td>
                  </tr>
                  {tableData.map((item) => {
                    return (
                      <Draggable
                        key={item.id}
                        draggableId={item.id.toString()}
                        index={item.position}
                      >
                        {(provided, shapshot) => (
                          <tr
                            ref={provided.innerRef}
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                            key={item.id}
                            className={`budget-subitem ${
                              shapshot.isDragging ? 'dragging' : ''
                            }`}
                          >
                            <td>
                              <Table className={tableClassnames}>
                                <tbody>
                                  <tr>{this.renderRows(item)}</tr>
                                </tbody>
                              </Table>
                            </td>
                            {!collapsedCategories.includes(item.id) &&
                              item.children && (
                                <Droppable
                                  droppableId={'budget-subitem-' + item.id}
                                  type="subItems"
                                >
                                  {(providedsub) => (
                                    <td
                                      {...providedsub.droppableProps}
                                      ref={providedsub.innerRef}
                                    >
                                      <Table className={tableClassnames}>
                                        <tbody>
                                          {item.children.map((child, j) => {
                                            return (
                                              <Draggable
                                                key={child.id}
                                                draggableId={child.id.toString()}
                                                index={j}
                                              >
                                                {(provided, shapshot) => (
                                                  <tr
                                                    ref={provided.innerRef}
                                                    {...provided.dragHandleProps}
                                                    {...provided.draggableProps}
                                                    key={child.id}
                                                    className={`${
                                                      shapshot.isDragging
                                                        ? 'dragging'
                                                        : ''
                                                    }`}
                                                  >
                                                    {this.renderRows(child)}
                                                  </tr>
                                                )}
                                              </Draggable>
                                            );
                                          })}
                                        </tbody>
                                      </Table>
                                    </td>
                                  )}
                                </Droppable>
                              )}
                          </tr>
                        )}
                      </Draggable>
                    );
                  })}
                  <tr>
                    <td>
                      <Table className={tableClassnames}>
                        <tbody>
                          <tr>
                            {totals.map((t, i) => (
                              <td key={i}>{t}</td>
                            ))}
                          </tr>
                        </tbody>
                      </Table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

export default BudgetTable;
